import React, { useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import "./ChooseTicket.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import moment from "moment";

function RenderEventDateComponent({
  ticketList,
  activeDateIndex,
  onDatePressed,
}) {
  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <>
      <div className="relative flex items-center">
        <MdChevronLeft
          className="opacity-50 cursor-pointer hover:opacity-100"
          onClick={slideLeft}
          size={40}
        />
        <div
          id="slider"
          className="w-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide"
        >
          {ticketList.map((item, index) => (
            <div
              onClick={() => {
                onDatePressed(item, index);
              }}
              className={
                activeDateIndex === index
                  ? "inline-block ml-2 border-double border-1 border-white-500 rounded-md"
                  : "inline-block ml-2 border-solid border-1 border-white-500 rounded-md"
              }
            >
              <div className="bg-[#3F8B99] text-white px-4 rounded-t">
                {moment(item.date, "YYYY-MM-DD HH:mm:SSZ").format("ddd")}
              </div>
              <div
                className={
                  activeDateIndex === index
                    ? "bg-gradient-to-b to-[#A57D24] from-[#FBF2A8] text-lg text-black font-bold"
                    : "bg-white text-lg text-black font-bold"
                }
              >
                {moment(item.date, "YYYY-MM-DD HH:mm:SSZ").format("DD")}
              </div>
              <div className="bg-[#3F8B99] text-white px-4 rounded-b">
                {moment(item.date, "YYYY-MM-DD HH:mm:SSZ").format("MMM")}
              </div>
            </div>
          ))}
        </div>
        <MdChevronRight
          className="opacity-50 cursor-pointer hover:opacity-100"
          onClick={slideRight}
          size={40}
        />
      </div>
    </>
  );
}

function ChooseTicketType({ eventDetails, onTicketSelected }) {
  const { ticket } = eventDetails;

  // const dateList = useRef(null);

  const [activeDateTickets, setActiveDateTickets] = useState(ticket[0]);

  const [activeDateIndex, setActiveDateIndex] = useState(0);

  const Ticket = ({ ticketObj, onItemClick, isBookingEnd }) => {
    const isTicketSold = ticketObj.tickets_left === 0 || isBookingEnd;

    return (
      <div
        className={`card ticket-item ${isTicketSold ? "is-disabled" : ""}`}
        style={{ margin: "5px", marginBottom: "10px" }}
        onClick={() => onItemClick(ticketObj)}
      >
        <img
          src="/ticket+icon.png"
          className="img-fluid ticket-image"
          alt="Responsive_image"
        />

        <Container className="text-start col-6 ticket-name fontloader">
          <Row>
            <Col xs={12}>
              <b>{ticketObj.name} </b>
            </Col>
            <Col className="descriptiontwoline" xs={12}>
              Price: ₹{ticketObj.price}
            </Col>
            <Col className="descriptionProceed" xs={12}>
              Proceed ahead for membership discounts.
            </Col>
          </Row>
        </Container>

        <div className="col ticket-name" style={{ fontSize: "20px" }}>
          <BsChevronRight />
        </div>
      </div>
    );
  };

  const handleOnEventDatePressed = (item, index) => {
    setActiveDateTickets(item);
    setActiveDateIndex(index);
  };

  const onTicketItemSelected = (ticketObj) => {
    onTicketSelected({ eventDate: ticket[activeDateIndex].date, ...ticketObj });
  };

  return (
    <>
      <div
        className="text-center booktickets container-fluid fontloader"
        style={{ marginTop: "20px" }}
      >
        <div>
          <b>Choose Date & Ticket Type</b>
        </div>

        <div>
          <RenderEventDateComponent
            ticketList={ticket}
            activeDateIndex={activeDateIndex}
            onDatePressed={handleOnEventDatePressed}
          />
        </div>

        <div className="container-scrol" style={{ marginTop: "10px" }}>
          <div className="left-col">
            {(activeDateTickets?.ticket || []).map((ticket, index) => (
              <Ticket
                ticketObj={ticket}
                onItemClick={onTicketItemSelected}
                isBookingEnd={activeDateTickets?.booking_closed || false}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ChooseTicketType;
