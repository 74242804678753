import React, { useEffect, useState, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import { useParams } from "react-router-dom";
import { Configuration } from "./Config";
import { RWebShare } from "react-web-share";
import { BsFillShareFill } from "react-icons/bs";
import { FaUserCircle, FaMobileAlt } from "react-icons/fa";
import moment from "moment";
import { isMobile } from "react-device-detect";
import html2canvas from "html2canvas";

function Thankyoupage({ eventDetail }) {
  const { eventId } = useParams();

  const [loading, setLoader] = useState(false);
  const [event, setEvent] = useState({});
  const [eventTime, setEventTime] = useState({});
  const [shareLink, setShareLink] = useState("");

  const [currentUser, setCurrentUser] = useState({
    name: "",
    mobile: "",
  });

  const exportRef = useRef();

  useEffect(() => {
    const user = localStorage.getItem("current-user");
    if (user) {
      setCurrentUser(JSON.parse(user));
    }
    callGetEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (event && event.id) {
      generateShareLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  async function generateShareLink() {
    const data1 = JSON.stringify({
      branch_key: "key_test_oaZ1nM5ZqMM9jXRGSr8qZmaeqBlrNXyT",
      data: {
        $og_title: `${event.name}`,
        $og_description: `${event.description}`,
        $og_image_url: `${event.cover_image}`,
        $desktop_url: `https://events.knocksense.com/${event.id}`,
        $android_url: `https://events.knocksense.com/${event.id}`,
        $ios_url: `https://events.knocksense.com/${event.id}`,
        isEvent: true,
        eventId: event.id,
      },
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data1,
    };

    fetch("https://api2.branch.io/v1/url", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          // call verify otp
          setShareLink(data.url);
        }
      });
  }

  const onSharePressed = (imageFile) => {
    fetch(imageFile)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "Share", { type: "image/png" });
        if (navigator.share) {
          navigator
            .share({
              title: `Share Event`,
              text: `Hi , i am attending *${event.name}.* %0a🗓️${eventTime}%0a📍${event.location} %0a`,
              url: `${shareLink}`,
              files: [file],
            })
            .then(() => {
              console.log("Successfully shared");
            })
            .catch((error) => {
              console.error("Something went wrong sharing the blog", error);
            });
        }
      });
  };

  const exportAsImage = async (element, imageFileName) => {
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    let htmlWidth = html.clientWidth;
    let bodyWidth = body.clientWidth;

    const newWidth = element.scrollWidth - element.clientWidth;

    if (newWidth > element.clientWidth) {
      htmlWidth += newWidth;
      bodyWidth += newWidth;
    }

    html.style.width = htmlWidth + "px";
    body.style.width = bodyWidth + "px";

    html2canvas(element, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      const image = canvas.toDataURL("image/png", 1.0);
      onSharePressed(image);
      console.log(image, imageFileName);
      html.style.width = null;
      body.style.width = null;
    });
  };

  /**
   * get event details
   */
  async function callGetEventDetails() {
    //set loader
    setLoader(true);
    fetch(`${Configuration.BASE_URL}event/detail/${eventId}`)
      .then((res) => res.json())
      .then((response) => {
        setLoader(false);
        if (!response.error) {
          setEvent(response.data);
          setEventTime(
            `${moment(event.date).format("DD MMM YYYY")} at ${moment(
              event.start_time
            ).format("hh:mm A")} `
          );
          generateShareLink();
        }
      });
  }

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  const DetectAndServe = () => {
    let os = getMobileOperatingSystem();
    if (os === "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.knocksense&pli=1";
    } else if (os === "iOS") {
      window.location.href =
        "https://apps.apple.com/in/app/knocksense-knockoff/id1539262930";
    } else {
      window.location.href = "https://www.knocksense.com/";
    }
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="fontloader">
          <Navbar className="container-row ">
            <img
              alt="knocksenselogo"
              src="/knocksenselogo.png"
              height="65px"
              className="col-3 mx-auto"
            />
          </Navbar>

          <div style={{ backgroundColor: "#1e9ab0" }}>
            <div className="checkoutbody fontloader">
              <h5>Booking Successful !</h5>
              <div className="ticket-credit-container">
                <div className="ticket-event-user-head">
                  {" "}
                  Ticket is credited in Knocksense Account{" "}
                </div>
                <div className="ticket-event-user-group">
                  <div className="ticket-event-user">
                    <FaUserCircle />{" "}
                    <div className="ticket-event-user-txt">
                      {currentUser.name}
                    </div>
                  </div>
                  <div className="ticket-event-user">
                    <FaMobileAlt />{" "}
                    <div className="ticket-event-user-txt">
                      {currentUser.mobile}
                    </div>
                  </div>
                </div>
              </div>
              <h5>
                Download the knocksense App to view
                <br />
                your Ticket
              </h5>
              <button
                onClick={DetectAndServe}
                type="button"
                className="btn fontloader"
                style={{
                  backgroundColor: "#ee7e1a",
                  marginTop: "20px",
                  color: "white",
                }}
              >
                <b>DOWNLOAD NOW</b>
              </button>
            </div>

            <div ref={exportRef}>
              <div className="container">
                <img
                  src={event.cover_image}
                  className="img-thumbnail imgthumbnai_new"
                  alt="thankyouimage"
                  style={{ border: "10px" }}
                />
              </div>
              <br />

              <div className="container">
                <div className=" flex flex-col bg-gray-100 rounded-lg p-2 container shadow-md">
                  <h6 className="text-orange-500 font-bold">I' am attending {event.name}</h6>
                  <h6 className="px-2 py-2 bg-orange-400 rounded-lg text-white font-bold my-1">ARE YOU JOINING US?</h6>
                </div>
              </div>

              <div className="container">
                {isMobile ? (
                  <div
                    onClick={() => exportAsImage(exportRef.current, "share")}
                  >
                    <h6 className="bg-orange-500 text-white flex shadow-md items-center justify-center px-4 py-2 rounded-md my-3 gap-3 hover:bg-orange-600 cursor-pointer">
                      <b>SHARE</b> <BsFillShareFill />
                    </h6>
                  </div>
                ) : (
                  <RWebShare
                    sites={[
                      "facebook",
                      "whatsapp",
                      "twitter",
                      "telegram",
                      "linkedin",
                      "copy",
                    ]}
                    data={{
                      text: `Hi , i am attending *${event.name}.* %0a🗓️${eventTime}%0a📍${event.location} %0a`,
                      url: `${shareLink}`,
                      title: `SHARE`,
                    }}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <h6 className="bg-orange-500 shadow-md text-white flex items-center justify-center px-4 py-2 rounded-md my-3 gap-3 hover:bg-orange-600 cursor-pointer">
                      <b>SHARE</b> <BsFillShareFill />
                    </h6>
                  </RWebShare>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Thankyoupage;
